.cropper, .has-cropper {
  display:none;
}
.cropper-avatar {
  width:200px;
  height:auto;
}

.cropping-area {
  max-width:500px;height:auto;
}

.make-crop {
  cursor:pointer;

}
img.grid {
   object-fit: cover;
  width:100%;height:100%;
}
.tiny {
  width: 2rem;
  min-width:2rem;
  overflow:hidden;
  height: 2rem; object-fit: cover;

}
img.thumb {
  width: 200px;
  height: 200px; object-fit: cover;
}

.crop-button {
  width:200px;
}

.cropper-popup {
  position:fixed;top:0;left:0;bottom:0;right:0;background-color:rgba(0,0,0,0.5);width:100%;height:100%;z-index:100;
}

.cropper-content {
  background-color:white;
  padding:0.5rem;
}

.close-image {
  right:0.5rem;
  top:0.5rem;

  i {
    color:white;
    font-size:2rem;
  }

  &:hover i {
    color: #A1CC3A;
  }


}


.ReactCrop img {
  max-width:60vw !important;max-height:60vh !important;
}