@import "cropper";

img.cover {
  object-fit: cover;
}

html,
body,
#root,
#root>div {
  &:not(.modal-wrapper.main) {
    display: table;
  }

  height:100%;
  margin:0;
  width:100%;
}

.easeload {
  opacity: 0;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -ms-transition: all 2s ease;
  -o-transition: all 2s ease;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.jodit-react-container {

  p,
  ul {
    margin-bottom: 1rem;
  }
}

.block-embed iframe {
  width: 100% !important;
}

img:not(.cover) {
  border-radius: 0.5rem;
}


.user-email {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}



.vertical-position-0 {
  object-position: 50% 0%;
}

.vertical-position-20 {
  object-position: 50% 20%;
}

.vertical-position-33 {
  object-position: 50% 33%;
}

.vertical-position-50 {
  object-position: 50% 50%;
}

.vertical-position-66 {
  object-position: 50% 66%;
}

.vertical-position-80 {
  object-position: 50% 80%;
}

.vertical-position-100 {
  object-position: 50% 100%;
}


.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}


.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.block-text ul,
.block-text p {
  margin-bottom: 1rem;
}

.top-14 {
  top: 3.5rem;
}

.top-18 {
  top: 4.5rem;
}

.bg-white-semi {
  background-color: rgba(255, 255, 255, 1);
}

.render img {
  width: 100%;
  object-fit: cover;
}

.render img:not(.gallery-item) {
  max-height: 300px;
}

.render .block-gallery img {

  height: 100%;
}

ul {
  list-style-type: disc;
  margin-left: 1rem !important;
}

.left-100 {
  left: 100%;
}

.right-100 {
  right: 100%;
}


.skeleton {

  svg,
  svg path {
    fill: #CCC;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0.2;
    }

    50% {
      opacity: 0.6;
    }

    100% {
      opacity: 0.2;
    }
  }


  opacity: 0.2;

  animation: fadeIn 1s ease-in-out infinite;

}

.skeleton-grid-item {
  padding-top: 100%;
}

.html-toggler {

  position: absolute;
  left: -1rem;
  top: 0;
  bottom: 0;

}

.html-design * {
  border: 1px solid black;
}

.html-attributes {
  color: #79c0ff;
}

.html-tag {
  color: #7ce485;
}

.html-value {
  color: #c9d1d9;
}

.react-tagsinput {

  border-width: 2px !important;
  border-color: #e2e8f0 !important;

  border-radius: 0.5rem;
}

.react-tagsinput-input {
  min-width: 200px;
  color: black !important;
}

.react-tagsinput-tag {
  border-radius: 0.5rem !important;
  border-width: 2px !important;
  position: relative !important;
  background: none !important;
}

.react-tagsinput-tag:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  opacity: 0.2;
  pointer-events: none;

}

.react-tagsinput-tag,
.react-tagsinput-input {
  font-size: 1.125rem !important;

}

div[id^=font-picker] {
  box-shadow: none !important
}

.tns-outer>button {
  display: none;
}

.tns-inner {
  max-width: 50vw !important;
}

div[id^=font-picker] ul.font-list {
  margin-left: 0 !important;
  z-index: 1000;
}

div[id^=font-picker] .dropdown-button {
  border-radius: 0.5rem;
  border: 2px solid #e2e8f0;
  height: auto !important;

  font-size: 1.125rem;
  background-color: white !important;
  padding: 0.5rem !important;

  overflow: hidden;
}

div[id^=font-picker].expanded .dropdown-button {

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

}


.tns-outer .grid {
  display: block !important;
}

.tns-nav {

  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 0 !important;

}

.tns-outer .text-h1 {
  overflow-wrap: anywhere !important;
}

.tns-nav button {
  border-radius: 999px;


  opacity: 0.5;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
}

.tns-nav button.tns-nav-active {

  opacity: 1 !important;
}

.bg-primary {

  &:not(.icon-picker-wrapper) {
    overflow: hidden;
  }

  &:not(.absolute) {
    position: relative;
  }

  text-shadow: 1px 1px 2px #000;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
  transition-duration: 300ms;

  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  transition-property: background-color,
  border-color,
  color,
  fill,
  stroke,
  opacity,
  box-shadow,
  transform;


}

/*
.bg-primary:before {
  position:absolute;left:0;right:0;top:0;z-index:10;width:calc(100%);height:2px;background-color:rgba(255,255,255,0.2);pointer-events: none;

content:"";
}
*/
.bg-primary .bg-primary {
  background-color: inherit !important;
  transition: none !important;
  transition-duration: inherit !important;
}

.bg-primary .bg-primary:before {
  display: none !important;
}

.bg-primary .bg-primary:after {
  display: none !important;
}

.bg-primary.cursor-pointer:hover,
.bg-primary.box-toggler:hover {
  background-color: black !important;
  color: white !important;
}

.hover-bg-theme-color {
  position: relative;
}

.bg-primary:after,
.hover-bg-theme-color:hover:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: 100%;

  pointer-events: none;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0) 100%);
  content: "";
}

/*
.modal {
  width:80vw;
  height:80vh;
}*/